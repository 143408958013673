html {
    background-color: #ffffff;
    color: #363636;
    height: 100%;
}

body {
    height: 100%;
}

.App {
    height: 100%;
}
.windowOpen #rect{
    fill: lime;
}
.windowClosed #rect{
    fill: red;
}
.header-logo {
    padding-left: 30px;
}

.header-user {
    text-align: right;
    padding-right: 20px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    position: fixed;
    top: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    color: white;
    border-bottom-color: #c2c2c2;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    z-index: 10;
}

.header-navigation a {
    color: #ffffff;
}

.sensor .entry {
    margin: 15px;
}

.sensor > div {
}

.sensor .subHeading {
    margin: 10px;
}

.sensor > div .alertsLeft {
    margin-right: 5px;
}

.sensor > div .alertsRight {
    margin-left: 5px;
}

.sensor > div .alerts input {
    width: 180px;
}

.sensor input {
    width: 100%;
}

.sensorStatus {
    border-style: solid;
    border-width: 1px;
    border-color: #686868;
    border-radius: 10px;
    padding: 10px;
    color: #ffffff;
}

.sensorActive {
    background: #3a863a;
}

.sensorInactive {
    background: #cc4141;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.inputBox {
    background: #ffffff;
}

a {
    color: #686868;
    text-decoration: none;
}

a:hover {
    color: #c2c2c2;
    text-decoration: none;
}

.content {
    padding-top: 70px;
    padding-bottom: 30px;
}

h2 {
    text-align: left;
    padding-left: 30px;
}

p {
    text-align: left;
    padding: 30px;
}

.hover {
    cursor: pointer;
}

.hover:hover {
    color: #c2c2c2;
}

.likeALink {
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    border-color: #FFBC00;
    background-color: #ffffff;
    padding: 10px;
    color: #686868;
    cursor: pointer;
    box-shadow: 1px 1px 5px;
}

.likeALink:hover {
    background-color: #f6f6f6;
}

.UiBox {
    margin: 30px;
    border-width: 1px;
    border-color: #cccccc;
    border-style: solid;
    border-radius: 5px;
    position: relative;
    min-height: 40px;
}

.UiBox .UiBoxHeader {
    position: absolute;
    top: -15px;
    left: 10px;
    background-color: #ffffff;
    padding: 5px;
    color: #cccccc;
}

.welcomeStepBox {
    height: 60px;
    width: 100%;
    border-color: #FFBC00;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    position: relative;
    margin-bottom: 20px;
}
.welcomeStepBox>.arrow {
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 55px;
}

.welcomeStepBox:last-child {
    margin-bottom: 0;
}

.welcomeStepBox .step {
    text-align: center;
    margin-top: 20px;
    position: absolute;
    width: 100%;
    font-weight: bold;
}

.welcomeInformationBox {
    min-height: 362px;
    border-color: #FFBC00;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    position: relative;
    left: 10px;
}

.welcomeInformationBox > .information {
    position: absolute;
    width: 100%;
    height: 100%;
    vertical-align: center;
}

.welcomeInformationBox > .information .information-content {
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
}

.welcomeInformationBox > .information .information-content>p {
    margin: 4px;
    padding: 5px;
}


.developers-heading {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1.2rem;
}

.api-generate {
    color: #6300c4;
    background-color: #e7e7e7;
    padding: 0.6rem;
    border-radius: 10px;
}

.loginHeading {
    font-size: 3rem;
    font-weight: bolder;
    color: #FFBC00;
    margin-bottom: 30px;
}


input:-webkit-autofill {
    -webkit-transition-delay: 9999999s;
}



/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FFBC00;
    height: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}